/*
 * @Author: FengZihao 
 * @Date: 2017-05-16 16:20:25 
 * @Last Modified by: zihao5@staff.sina.com.cn
 * @Last Modified time: 2017-05-19 15:43:28
 */
(function () {
    var _maq = _maq || [];  // 脚本检测传递的信息
    var info = {
        'account': '',   // App的名称，例如SinaNews
        'channel': '',   // 当前H5的渠道来源
        'point': '',     // 加码页面名称，H5
        'platform': ''   // 设备平台名称（android,ios,wp,pc）
    };
    var ua = navigator.userAgent;
    function parseURL() {
        var loc = window.location;
        return {
            source: loc.href,
            protocol: loc.protocol.replace(':', ''),
            host: loc.hostname,
            query: loc.search,
            params: (function () {
                var ret = {},
                    seg = loc.search.replace(/^\?/, '').split('&'),
                    len = seg.length, i = 0, s;
                for (; i < len; i++) {
                    if (!seg[i]) { continue; }
                    s = seg[i].split('=');
                    ret[s[0]] = s[1];
                }
                return ret;
            })(),
            path: loc.pathname.replace(/^([^\/])/, '/$1'),
            relative: (loc.href.match(/tps?:\/\/[^\/]+(.+)/) || [, ''])[1],
            segments: loc.pathname.replace(/^\//, '').split('/')
        };
    }
    function getPlatform() {
        var platform;
        if (/(iPhone|iPad|iPod|iOS)/i.test(ua)) {
            platform = 'ios';
        } else if (/(Windows Phone)/i.test(ua)) {
            platform = 'wp';
        } else if (/(Android)/i.test(ua)) {
            platform = 'android';
        } else {  //pc
            platform = 'pc';
        }
        return platform;
    }
    function getChannel() {
        var urlObj = parseURL();
        var searchReg = /^http.*?\.([^\.]*?)\.(?:com|cn)/i;
        var searchEngs = ['baidu', 'google', 'sogou', 'soso', 'so', '360', 'sm', 'easou', 'yicha', 'haosou', 'uodoo', 'sm-tc'];
        var wm = urlObj.params.wm;
        var outWmS4 = ['3285', '3288', '3272', '3283', '3286', '3291', '4002', '3287', '3255', '3296', '3298', '3239', '3269', '3271', '3217', '3219', '3220', '3225', '3274', '3282', '3222', '3223', '3227', '3233', '3243', '3213', '3229', '3185', '3170', '3293' ];
        var channel;
        var refer = document.referrer;
        if (wm) {  // 依据wm值
            var wmS4 = wm.substring(0, 4);
            if (wmS4 === '3049') {
                return 'wbH5';
            } else if (outWmS4.indexOf(wmS4) !== -1) {
                return 'compony';
            } else {
                return 'browser';
            }
        } else if (!refer) {   // 直访，忽略后面的判断
            return 'compony'
        } else {
            // 搜索
            var searchList = refer.match(searchReg);
            var searchEng = searchList && searchList.length > 1 ? searchList[1] : '';
            if (searchEngs.indexOf(searchEng) !== -1) {
                console.log(searchEng, 'search');
                return 'search'
            }
            // 无法判断
            return 'compony'
        }
    }
    function qusetMobileInit() {
        try {
            var qusetMobileConfig = window.qusetMobileConfig;
            if (typeof qusetMobileConfig !== "undefined") {
                info.account = qusetMobileConfig.account !== undefined ? qusetMobileConfig.account : '';
                info.point = qusetMobileConfig.point !== undefined ? qusetMobileConfig.point : '';
                info.platform = qusetMobileConfig.platform !== undefined ? qusetMobileConfig.platform : '';
                info.channel = qusetMobileConfig.channel !== undefined ? qusetMobileConfig.channel : '';
            }
            info.account = info.account || 'SinaNews';
            info.point = info.point || 'H5';
            info.platform = info.platform || getPlatform();
            info.channel = info.channel || getChannel();
            window._maq = window._maq || [];
            window._maq.push(
                ['account', info.account],
                ['channel', info.channel],
                ['point', info.point],
                ['platform', info.platform]);
            var ma = document.createElement('script');
            ma.type = 'text/javascript';
            ma.async = true;
            ma.src = ('https:' == document.location.protocol ? 'https://' : 'http://') + 'www.qchannel03.cn/m2.js';
            var s = document.getElementsByTagName('script')[0] || document.body.children[0];
            s.parentNode.insertBefore(ma, s);
            for (var i = 0; i < 4; i++) {
                console.log(window._maq[i][0], ':', window._maq[i][1]);
            }
        } catch (err) {
            console.log('QusetMobile Error');
        }
    }
    qusetMobileInit();
    // if (window.addEventListener) {
    //     window.addEventListener('load', qusetMobileInit);
    // } else if(window.attachEvent){
    //     window.attachEvent('onload',qusetMobileInit);
    // }else{
    //     window['onload'] = qusetMobileInit;
    // }
})()
